/* RESPONSIBLE TEAM: team-pricing-and-packaging */
export const NO_FILTER = 'any';
export const WITHOUT_FILTER_VALUE = 'null';

export const SEAT_FILTER_TYPE = 'seat';
export const ROLE_FILTER_TYPE = 'role';
export const STATUS_FILTER_TYPE = 'status';
export const TEAM_FILTER_TYPE = 'team';
export const COPILOT_FILTER_TYPE = 'copilot';
export const LAST_ACTIVE_FILTER_TYPE = 'last_active';
export const ASSIGNMENT_LIMIT_FILTER_TYPE = 'assignment_limit';
export const TEAMMATE_FILTER_TYPE = 'teammate_filter';
export const ALIAS_FILTER_TYPE = 'alias';

export const ASSIGNMENT_LIMIT_COMPARATORS = {
  greaterThan: 'gt',
  lessThan: 'lt',
  is: 'eq',
  isNot: 'ne',
  hasAnyValue: 'any',
};

export const STATUS_OBJECT = {
  active: 'active',
  away: 'away',
  reassign: 'away-and-reassigning',
};

export const COPILOT_FILTER_OBJECT = {
  unlimited_usage: 'unlimited',
  limited_usage: 'included',
};
